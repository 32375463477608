<template>
  <div class="main-layout">
    <div id="main">
      <!-- 			<div id="top-logo">
				<img src="images/physi.jpg"/>
			</div> -->
      <h1>Urban Nature Explorer</h1>

      <div id="about-image">
        <img src="images/about.png" />
      </div>
      <div id="content">
        <p>
          The Urban Nature Explorer, as a web-based interactive platform, aims
          to support the development and the visualization of alternative
          nature-based solution scenarios and assess their costs, benefits, and
          impacts on selected urban sustainability challenges, such as climate
          change, stormwater management or social cohesion.
        </p>
        <p>
          The tool was developed as part of the NATURVATION (NATure-based URban
          innoVATION), an EU Horizon 2020 research project. A non-European case
          study was added as part of the UNA global extension project funded by
          the British Academy. Since 2022, PHYSI Solutions Ltd. holds solely the
          right to manage and develop the tool further.
        </p>
        <p>
          The Urban Nature Explorer has a map-based interface that can be
          customized to any location and used in the early visioning stage of
          participatory planning processes. Physi Solutions offers to tailor the
          tool according to specific local contexts, including:
        </p>

        <ul>
          <li>customization to local challenges</li>
          <li>technically feasible NBS according to location</li>
          <li>cost and impact modeling</li>
          <li>scenario projections</li>
          <li>visual impacts representation</li>
        </ul>
      </div>
    </div>
    <main-layout />
  </div>
</template>
<script>
import MainLayout from "../components/MainLayout";
export default {
  components: { MainLayout },
};
</script>
<style lang="scss" scoped>
#main {
  width: 960px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 165px;
}
h1 {
  margin-top: 15px;
  margin-bottom: 75px;
}
#app #content {
  padding: 0;
}
p {
  margin-top: 0;
}
#app #content {
  text-align: justify;
}
#about-image {
  float: right;
}
#top-logo {
  text-align: center;
  img {
    height: 75px;
  }
}
</style>
