<template>
  <div id="main-layout">
    <img id="sun" src="/images/layout/sun.png" />
    <img id="cloud" src="/images/layout/cloud.png" />
    <img id="cloud2" src="/images/layout/cloud2.png" />
    <footer id="footer-wrapper">
      <img id="forest" src="/images/layout/forest.png" />
      <img id="green_roof" src="/images/layout/green_roof.png" />
      <img id="lake" src="/images/layout/lake.png" />
      <img id="park" src="/images/layout/park.png" />
      <footer id="about-footer">
        <div id="about">
          <p>
            <a href="#" @click.stop.prevent="aboutPage">About</a>
            <a href="#" @click.stop.prevent="privacyPage" target="_blank"
              >Privacy Policy</a
            >
          </p>
        </div>
        <!-- 			<div id="social">
				<div id="icons">
					<div class="icon">
						<div id="circle1" class="circle"></div>
						<a href="https://twitter.com/naturvation" id="twitter" target="_blank"></a>
					</div>
					<div class="icon">
						<div id="circle2" class="circle"></div>
						<a href="https://www.instagram.com/naturvation/" id="insta" target="_blank"></a>
					</div>
					<div class="icon">
						<div id="circle3" class="circle"></div>

						<a href="https://www.youtube.com/channel/UC-LhZeKLJNY9eafpmII9Z8w" id="yt" target="_blank"></a>
					</div>
				</div>
			</div> -->
      </footer>
      <funding></funding>
    </footer>
  </div>
</template>
<script>
import Funding from "./Funding";
export default {
  components: { Funding },
  methods: {
    aboutPage: function (e) {
      e.preventDefault();
      this.$router.push({
        path: "/About",
      });
    },
    privacyPage: function (e) {
      e.preventDefault();
      this.$router.push({
        path: "/Privacy",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/_variables.scss";
@import "../styles/_mixins.scss";
@import "../styles/main.scss";
</style>
